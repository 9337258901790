.orders-container {
  padding: 30px;
}

.ordered-items-card {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(231, 231, 231);
}
.ordered-items-card > * {
  margin-bottom: 6px;
}

.ordered-items-card .order-id-container {
  font-weight: 600;
}

.ordered-items-card .payment-id-container {
  font-size: small;
}
.payment-id-container {
  color: grey;
}

.orders-container .price-container {
  color: grey;
  font-size: small;
}

.orders-container .products-card {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  color: grey;
  margin: 1em 0;
  flex-grow: 0;
}

.ordered-items-card img {
  width: 90px;
  object-position: top;
  object-fit: contain;
}

.ordered-items-card .products-card .description {
  display: flex;
  flex-flow: column nowrap;
  /* align-items: center; */
  padding: 0 1em;
}

.ordered-items-card .products-card .description > * {
  margin-bottom: 5px;
}
.ordered-items-card .products-card .name {
  color: black;
  font-size: small;
}

.ordered-items-card .products-card .qty {
  font-size: small;
}

.ordered-items-card .products-card .price {
  font-size: small;
}
.payment-btn-group{
  display: flex;
}
.orders-container button{
  text-align: center;
  font-weight: 800;
  border: none;
  border-radius: 20px;
  font-size: .8rem;
  color: white;
  background-color: var(--primary-color);
  padding: 0.5rem 2rem;
  margin: 0;
  margin-right: 1em;
}