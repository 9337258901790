.cart-product-card {
  display: flex;
  margin-bottom: 10px;
  box-shadow: 0px 0px 31px -3px rgba(232, 232, 232, 0.75);
  border-radius: 10px;
  padding: 10px;
  position: relative;
  flex-flow: column;
  align-items: center;
  font-weight: 300;
  
}

.cart-img {
  width: 180px;
  object-fit: cover;
  height: 120px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.cart-products-container .product-description {
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: 15px;
  font-size: smaller;
}

.product-description h3 {
  margin-bottom: 5px;
  font-weight: 400;
}

.product-description :not(:first-child) {
  font-size: 0.8rem;
  color: grey;
}
.button-section {
  height: 70px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
}
.button-section span {
  padding: 5px;
  font-size: 1rem;
  align-self: center;
}

.button-section .count-btn-container {
  display: flex;
  font-size: 25px;
  width: 100%;
  justify-content: center;
}

.secondary-btn-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 100%;
  position: absolute;
  top: 20px;
}

.secondary-btn-section > * {
  margin: 0px 20px;
  color: var(--primary-color);
}
.secondary-btn-section > *:hover{
  color: black;
  cursor: pointer;
}

.counter-btn {
  font-size: 20px;
  border: none;
  padding: 5px 20px;
  border-radius: 10px;
  margin: 0px 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(225, 225, 225);
}

.counter-btn:hover{
  cursor: pointer;
  color: white;
  background-color: black;
  border: 1px solid black;
}

@media (min-width: 768px) {
  .cart-products-container {
    flex-grow: 1;
  }
}
