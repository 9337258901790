.funding-modal-container {
  position: fixed;
  inset: 0;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto;
}
.funding-modal-container .funding-input-container {
  /* position: absolute; */
  /* inset: 0; */
 width: 100%;
  max-height: 100%; /* Maximum height to allow scrolling */
  overflow-y: auto; 
}
.funding-modal-container .funding-content{
  background-color: rgb(255, 255, 255);
  margin: auto;
  width: 40%;
  /* height: 520px; */
  /* text-align: center; */
  /* border-radius: 10px; */
  /* padding: 2em 2em 3em; */
  position: relative;
  margin-top: 2em;
  /* display: flex; */
}
 
.funding-modal-container .cross-tab-icon {
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
}
.funding-img{
  width: 50%;
  height: 50vh;
}
.funding-img img{
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.funding-details{
 padding: 2em;
}
.funding-input-container h2 {
  color: rgb(33, 33, 33);
  margin:  10px 0;
  font-weight: 500;
  /* text-align: center; */
  font-size: 2rem;
  font-weight: 600;
}

.funding-details .explore-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .5em;
  /* border-radius: 10px; */
}

.funding-details .explore-btn span{
  /* margin-left: 1em; */
}
 
.funding-details .download-btns{
  display: flex;
  flex-wrap: wrap;
}
.funding-content .x-bank-details{
  padding: 1em;
  background: #f7f7f7;
  margin: 0 0 1em;

}
.funding-content .x-bank-details div{
display: flex;
justify-content: space-between;
font-size: .9em;
margin: .5em 0;
}
.funding-content .x-bank-details div button{
  border:  unset;
  display: flex;
}
.funding-content .x-bank-details button svg{
  margin-left: .2em;
}

.funding-modal-container  .form-group {
  /* margin-bottom: 30px; */
  background: #f7f7f7;
  padding: 1em;
}

.funding-modal-container  label {
  display: block;
  margin-bottom: 8px;
  font-size: 1em;
  color: #333;
}

.funding-modal-container  .required {
  color: #dc3545;
  margin-left: 2px;
}

.funding-modal-container input,.funding-modal-container  select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.funding-modal-container input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}
@media (max-width: 767px) {

  .funding-modal-container .funding-content{
    width: 90%;
  }
}
