/* ProductImage.css */

.product-details-image {
  /* justify-content: center; */
  /* align-items: center; */
  /* height: 100%; */
}

.product-details-image img {
 width: 100%;
 height: 100%; 
  object-fit: contain;
}
.product-details-image .description-container{
  display: none;
  white-space: pre-wrap;
  line-height: 26px;
  padding: 1em 0;
  border-top: 1px solid #eee;
  margin: 2em 0;
}
.product-details-image .description-container h3{
  font-weight: 500;
  font-size: 1.3em;
  margin-bottom: .5em;
}
.x-thumbnails .slick-slide {
  padding: 0 .1em;
  cursor: pointer;
}
.x-thumbnails .slick-slider button{
  display: none !important;
}
@media (max-width: 767px) {
  .product-details-image{
    /* height: 40vh; */
    width: 100%;
  }
  .product-details-image img{
    /* height: 40vh; */
    width: 100%;
    object-position: top;
    object-fit: cover;
  }


}
@media (min-width: 768px) {
  .product-details-image  {
    width:  65%;
    padding: 0em 4em 0;
  }
  .product-details-image img {
    width: 100%;
    /* max-height: 500px; */
  }
  .product-details-image .description-container{
    display: block;
  }
}
