.video-container {
  display: flex;
  background-color: rgb(255, 255, 255);
  justify-content: space-around;
  flex-flow: row nowrap;
  margin: 5em 0;
}

.video-container .video-card {
  display: flex;
  flex-flow: column nowrap;

  width: 35vw;
  height: 49.8vw;
  margin: 5px;
  position: relative;
  border-radius: 25px;
  box-shadow: 0px -1px 84px -16px rgba(117, 117, 117, 1);
  cursor: pointer;
}

.video-container .notch {
  left: 23%;
  right: 0;
  text-align: center;
  position: absolute;
  background-color: rgb(27, 27, 27);
  height: 2.3vw;
  width: 52%;
  border-radius: 4px 4px 8px 8px;
}

.video-container video , .video-container img {
  object-fit: cover;
  border-radius: 22px;
  opacity: 1;
  width: 100% ;
  height: 100%  ;
}
.video-container .video-card div {
  /* height: 100%  ; */
  height: 119.9%  ;
}
.video-container .video-card h3 {
  font-size: 0.9em;
  position: absolute;
  color: white;
  bottom: 30px;
  left: 0;
  right: 0;
  text-align: center;
}

.video-section-img {
  width: 100%;
  position: relative;
}

.hero-img-container {
  background-color: black;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.hero-img-container img {
  opacity: 0.6;
}

.hero-img-container h1 {
  opacity: 0.9;
  text-align: center;
  font-size: 3rem;
  padding: 10px;
  color: white;
  position: absolute;
  top: 5rem;
}


@media (max-width: 767px) {
  .video-container video , .video-container img{
    border-radius: 10px;
  }
}


@media (min-width: 768px) {
  .video-container {
    justify-content: space-evenly;
  }
  .video-container .video-card {
    height: 38vw;
    width: 18vw;
  }

  .video-container .notch {
    height: 1.3vw;
  }

  .video-container .video-card h3 {
    font-size: 2em;
  }

  .hero-img-container h1 {
    font-size: 6em;
  }
}
