.address-modal-container {
  position: fixed;
  inset: 0;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.2);
  margin: auto;
}
.address-modal-container .address-input-container {
  /* position: absolute; */
  /* inset: 0; */
 width: 100%;
  max-height: 100%; /* Maximum height to allow scrolling */
  overflow-y: auto; 
}
.address-modal-container .address-content{
  background-color: rgb(255, 255, 255);
  margin: auto;
  width: 30%;
  /* height: 520px; */
  /* text-align: center; */
  border-radius: 10px;
  padding: .1em 0 1em;
  position: relative;
  margin-top: 2em;
}
 
.address-modal-container .cross-tab-icon {
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
}
.address-input-container h2 {
  margin-top: 10px;
  color: rgb(33, 33, 33);
  margin-bottom: 25px;
  font-weight: 400;
  text-align: center;
  font-size: 1.1rem;
}


.address-modal-container
.name-container {
  display: flex;
  flex-flow: column nowrap;
  padding: .5em 1.5em;
  
}

.address-modal-container .name-container > label {
  margin-bottom: 5px;
  font-size: .9em;
  color: rgb(108, 108, 108);;
}

.address-modal-container .name-container input {
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid rgb(210, 210, 210);
}
/* 
.address-modal-container .address-input-container input {
  padding: 8px 16px;
  display: block;
  width: 100%;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 5px;
} */

.address-modal-container .submit {
  padding: 15px 10px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 1.1rem;
  border: none;
  margin-bottom: 10px;
  color: white;

  background-color: var(--primary-color);
}

.address-modal-container .submit:hover {
  cursor: pointer;
  background-color: black;
}
.address-modal-container .btn-container {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.address-modal-container .btn-container button {
  border: none;
  padding: 8px 16px;

  border-radius: 5px;
}

.address-modal-container .btn-container button:hover {
  cursor: pointer;
  background-color: var(--secondary-background-color-onhover);
}

.address-modal-container .btn-container button:last-child {
  flex-grow: 1;
  margin-left: 10px;
}
 
@media (max-width: 767px) {

  .address-modal-container .address-content{
    width: 90%;
  }
}
