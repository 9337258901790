 
.x-buydata .form-container {
    max-width: 600px;
    margin: 0px auto;
    padding: 1em 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  }
  
  .x-buydata  h1 {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 20px;
    margin-left: 0em;
    /* text-align: center; */
  }
  
  .x-buydata  .form-group {
    /* margin-bottom: 30px; */
    background: #f7f7f7;
    padding: 1em;
  }
  
  .x-buydata  label {
    display: block;
    margin-bottom: 8px;
    font-size: 1em;
    color: #333;
  }
  
  .x-buydata  .required {
    color: #dc3545;
    margin-left: 2px;
  }
  
  .x-buydata input,.x-buydata  select {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .x-buydata input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
  }
  
  .x-buydata .form-progress {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .x-buydata .step-indicator {
    font-size: 14px;
    color: #666;
    margin-right: 15px;
  }
  
  .x-buydata .step-dots {
    display: flex;
    gap: 8px;
  }
  
  .x-buydata  .step-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ddd;
  }
  
  .x-buydata  .step-dot.active {
    background-color: #28a745;
  }
  
  .x-buydata  .x-cta{
    display: flex;
    justify-content: space-between;
  }
  .x-buydata  .next-button, .back-button {
    display: block;
    padding: .5em;
    width: 42%;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .x-buydata  .back-button {
    background-color: #fff;
    color: #000;
    /* padding: 0; */
    font-size: .8em;
    width: inherit;
    /* border: 1px solid #000; */
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .x-buydata  .next-button:hover {
    background-color: #c82333;
  }
  
  .x-buydata  .next-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(220,53,69,0.25);
  }
  
  
  .x-buydata .x-plans{
  display: flex;
  flex-wrap:wrap;
}
.x-buydata .x-plan-box{
  width: 32%;
  text-align: center;
  background-color: #f7f7f7;
  margin: .5em .1em;
  border-radius: 5px;
  cursor: pointer;
}
.x-buydata .x-plan-content{
  padding: 1em;
}
.x-buydata .x-plan-name{
  font-weight: 600;
}
.x-buydata .x-plan-duration{
  font-size: .8em;
  margin: .5em 0;
}
.x-buydata .x-plan-amount{
  font-size: .9em;
}
.x-buydata .x-plan-type{
  font-size: .3em;
  background: #aaa;
  color: #fff;
  padding: 2px;
}

.x-buydata .x-plans-details{
  background: #f7f7f7;
  padding: 1em;
  margin: 0em 0 1em;
}
.x-buydata .x-plans-details .x-flex{
  display: flex;
  justify-content: space-between;
  padding: .5em 0;
}