
.promo-banner {
    background: linear-gradient(to right, #FF4B2B, #FF416C);
    color: white;
    padding: 40px;
    border-radius: 20px;
    /* max-width: 600px; */
    margin: 20px auto 0;
    position: relative;
    overflow: hidden;
}
.promo-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.2;
}
.download-button {
    background-color: white;
    text-decoration: unset;
    color: #1e4d3e;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
}
.download-button img {
    margin-right: 10px;
    height: 20px;
    width: 20px;
} 
.promo-text {
    margin-top: 10px;
    font-size: 1.1em;
    margin: 0px 0 20px;
}
.promo-text strong {
    color: #ffd700;
}
.wine-illustration {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 150px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: contain;
}
.discount-tag {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
}
.customer-review{
    background-color: #fff;
    border-radius: 10px;
    padding: 1em;
}

.review-title {
    font-size: 1.5em;
    font-weight: bold;
    margin: 40px 0 20px;
    line-height: 1.2;
}
.customer-review video{
    width: 100%;
    height: 300px;
    margin-top: 1em;
}
.customer-review .x-review-card{
    display: flex;
    color: #000;
}
.customer-review .x-profilepic{
    width: 50px;
    height: 50px;
    border-radius: 100%;
}
.customer-review .x-review-card .x-desc{
    padding: 0 .5em;
}
.customer-review svg{
    width: 12px;
}

.x-more-info .x-faq-card{
    padding: 0;
    border: 1px solid #eee ;
    margin: .5em 0;
    border-radius: 10px;
}
    .x-more-info .x-faq-question{
    background: unset;
    border: unset;
    padding: 1em;
    color: #fff;
    width: 100%;
    text-align: left;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    font-size: .9em;
}
.x-more-info .x-faq-answer{
    padding:   1em;
    font-weight: 400;
}
@media(max-width: 767px) {
    .promo-banner{
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    }
.customer-review video{
    height: 200px;
}
}