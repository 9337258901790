
.products-page-container .product-details-description {
  width: 80%;
  /* display: flex; */
  flex-flow: column nowrap;
  /* justify-content: space-evenly; */
  line-height: 25px;
  padding-bottom: 1em;
}

.product-details-description .description-container.x-content{
  display: block;
  white-space: pre-wrap;
  line-height: 26px;
  margin: 2em 0;
}
.product-details-description .description-container h3{
  font-weight: 500;
  font-size: 1.3em;
  margin-bottom: .5em;
}

.products-page-container .product-details-description h1 {
  margin: 2rem 0;
  font-weight: 400;
}
.gender-container {
  font-size: 0.9rem;
}

.description-container {
  font-size: 0.9rem;
  padding: 1em 0;
  border-top: 1px solid #eee;
  /* display: flex; */
  /* font-size: 1rem; */
  /* line-height: 22px; */
  /* margin-bottom: 20px; */
  /* text-align: justify; */
}

.description-container .xbold , .xperks .xbold{
  font-weight: 600;
  color: black;
}
.description-container span{
  border: 1px solid rgb(214, 214, 214);
  margin: 3px 5px;
  padding: 0px 10px;
  border-radius: 10px;
  display: inline-block;
  font-size: .9em;
  cursor: pointer;
}
.description-container span.active{
  background: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}
.description-container span.x-disable{
  border-color: #eee;
  color:  rgb(214, 214, 214);
}

.gender-container > span {
  font-weight: 600;
  color: black;
}
.gender-container {
  color: grey;
}

.size-container {
  font-size: 0.9rem;
  color: grey;
}
.size-container > span {
  font-weight: bold;
  color: black;
}

.product-price-container {
  margin-bottom: 20px;
}

.product-original-price {
  font-size: 1.2rem;
  color: grey;
  text-decoration: line-through;
  text-decoration-color: red;
  font-weight: 400;
  margin-right: 10px;
}

.product-discount-price {
  font-size: 1.2rem;
  font-weight: 400;
}
.ratings-reviews {
  font-size: 0.9rem;
  margin-top: -20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.ratings-reviews > * {
  margin-right: 5px;
}

.ratings-reviews > :first-child {
  font-weight: bold;
}
.ratings-reviews .review {
  color: grey;
}

.tags {
  display: flex;
  margin: 15px 0;
}
.tags > * {
  margin-right: 10px;
}
.xperks{
  background: #f7f7f7;
  font-size: .9em;
  padding: 1em;
}
.xperks .xperks-content{
    display: flex;
    justify-content: space-between; 
    
}
.xperks .xperks-content .x-head{
   font-weight: 500; 
  }
.xperks .xperks-content div{
width: 88%; 
}
.xperks .xperks-content a{
  color: #000
}
.product-card-buttons-container {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 30px;
}

.product-card-buttons-container .add-to-cart-btn {
  font-weight: 800;
  border: none;
  margin-bottom: 8px;
  border-radius: 10px;
  font-size: 1em;
  color: white;
  background-color: var(--primary-color);
  padding: 15px 45px;
  text-transform: uppercase;
}

.product-card-buttons-container .add-to-cart-btn:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.product-card-buttons-container .add-to-wishlist-btn {
  border: none;
  background-color: rgb(232, 232, 232);
  color: var(--primary-color);
  border-radius: 10px;
  padding: 15px 45px;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1em;
}
.product-card-buttons-container .add-to-wishlist-btn.whatsapp{
  color: #424242;
  background: #fff;
  border: 1px solid #424242;
  margin-bottom: 8px;
}
.product-card-buttons-container .add-to-wishlist-btn:hover{
  background-color: rgb(214, 214, 214);
  color: rgb(0, 0, 0);
  box-shadow: none;
}
.product-card-buttons-container .add-to-wishlist-btn.whatsapp:hover{
  background-color: #f7f7f7;
  box-shadow: none;
}

@media (max-width: 767px) {
  .product-card-buttons-container .add-to-cart-btn , .product-card-buttons-container .add-to-wishlist-btn{
    font-size: .9em;
  }
}
@media (min-width: 768px) {
  .products-page-container .product-details-description {
    width: 25vw;
    /* height: 300px; */
    justify-content: space-between;
    line-height: 26px;
    margin-top: -30px;
  }

  .tags {
    display: flex;
    margin-bottom: 30px;
  }
.product-details-description .description-container.x-content{
  display: none;
}
}
