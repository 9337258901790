.signin-modal-container {
  position: fixed;
  inset: 0;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto;
}
.signin-modal-container .signin-input-container {
  /* position: absolute; */
  /* inset: 0; */
 width: 100%;
  max-height: 100%; /* Maximum height to allow scrolling */
  overflow-y: auto; 
}
.signin-modal-container .signin-content{
  background-color: rgb(255, 255, 255);
  margin: auto;
  width: 40%;
  /* height: 520px; */
  /* text-align: center; */
  /* border-radius: 10px; */
  /* padding: 2em 2em 3em; */
  position: relative;
  margin-top: 2em;
  /* display: flex; */
}
 
.signin-modal-container .cross-tab-icon {
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
}
.signin-img{
  width: 50%;
  height: 50vh;
}
.signin-img img{
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.signin-details{
 padding: 2em;
}
.signin-input-container h2 {
  color: rgb(33, 33, 33);
  margin:  10px 0;
  font-weight: 500;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
}

.signin-content p{
 text-align: center;
 font-weight: 300 ;
}
.signin-content .x-subtitle{
  padding: .5em 0 2em ;
}
.signin-content .x-foot{
  padding: 2em 0 .5em ;
  text-align: center;
  font-size: .9em;
}
.signin-content a{
  color: unset;
}
.signin-modal-container  button {
  padding: 15px 10px;
  border-radius: 5px;
  font-weight: 600;
  width: 100%;
  border: 1px solid #ddd;
  margin-top: 1em;
background: #fff;
justify-content: center;
display: flex;
font-size: 1em;
  /* color: var(--primary-color); */
}
.signin-content svg{
  margin-right: 1em;
}
.signin-content  svg {
  width: 20px !important;
}

.signin-modal-container  button:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}
.signin-content button.x-google{
  background: #DB4437;
  color: #fff;
}
.signin-content button.x-email{
  background: var(--primary-color);
  color: #fff;
}

/* 
.signin-modal-container .signin-input-container input {
  padding: 8px 16px;
  display: block;
  width: 100%;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 5px;
} */

.signin-modal-container .submit {
  padding: 15px 10px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 1.1rem;
  border: none;
  margin-bottom: 10px;
  color: white;

  background-color: var(--primary-color);
}

.signin-modal-container .submit:hover {
  cursor: pointer;
  background-color: black;
}
.signin-modal-container .btn-container {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.signin-modal-container .btn-container button {
  border: none;
  padding: 8px 16px;

  border-radius: 5px;
}

.signin-modal-container .btn-container button:hover {
  cursor: pointer;
  background-color: var(--secondary-background-color-onhover);
}

.signin-modal-container .btn-container button:last-child {
  flex-grow: 1;
  margin-left: 10px;
}
 
@media (max-width: 767px) {

  .signin-modal-container .signin-content{
    width: 90%;
  }
}
