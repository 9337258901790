

.payment-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.payment-method:hover {
  background-color: #f5f5f5;
}

.payment-method__name {
  font-size: 16px;
  margin: 0;
}

.payment-method__number {
  font-size: 14px;
  color: #666;
  margin: 5px 0 0;
}

.payment-method__chevron {
  font-size: 24px;
  color: #999;
}

@media (max-width: 768px) {
  .wallet-cards {
    flex-direction: column;
  }

  .wallet-card {
    flex: 1 1 auto;
  }
}

