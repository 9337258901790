.address-container {
  padding: 15px;
  border-radius: 10px;
}

.checkout-container .address-card {
  border: 1px solid rgb(232, 232, 232);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.address-container .address-card .name {
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: 400;
}

.address-container .address-card .address {
  color: white;
  font-size: 15px;
  margin-right: 5px;
  color: grey;
}

.add-new-address-btn-container {
  text-align: center;
}

.add-new-address-btn:hover {
  cursor: pointer;
  background: black;
}

.add-new-address-btn {
  border: none;
  padding: 8px 20px;
  border-radius: 7px;
  margin-top: 10px;
  color: white;
  background: var(--primary-color);
}

.address-btns {
  margin-top: 10px;
}

.address-btns button {
  margin-right: 10px;
  padding: 5px 18px;
  border: none;
  border-radius: 6px;
}

.address-btns button:hover {
  cursor: pointer;
  background-color: var(--secondary-background-color-onhover);
}

.address-card input {
  margin-right: 10px;
}

@media (min-width: 768px) {
  .address-container {
    width: 50vw;
  }
}
