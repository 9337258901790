.user-profile-container {
  width: 90vw;
  margin: auto;
  margin-top: 100px;
  box-shadow: 0px 0px 23px 0px rgb(222, 222, 222);
  border-radius: 10px;
}

.user-profile-heading {
  text-align: center;
  margin-bottom: 15px;
}

.link-container {
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  padding: 15px;
  background-color: rgb(236, 236, 236);
}

.link-container > a {
  color: black;
  text-decoration: none;

  font-weight: 600;
}

@media (min-width: 768px) {
  .x-wallet-dashboard .wallet-cards {
    display: flex;
  }
  .user-profile-container {
    width: 400px;
  }
}

.x-wallet-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.x-wallet-dashboard .header {
  margin-bottom: 20px;
}

.x-wallet-dashboard .header__title {
  font-size: 24px;
  margin-bottom: 5px;
}

.x-wallet-dashboard .header__nav {
  font-size: 14px;
  color: #666;
}

.x-wallet-dashboard .header__nav-link {
  color: #666;
  text-decoration: none;
}

.x-wallet-dashboard .header__nav-separator {
  margin: 0 5px;
}

.x-wallet-dashboard .wallet-cards {
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.x-wallet-dashboard .wallet-card {
  flex: 1 1 300px;
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.x-wallet-dashboard .wallet-card--main {
  /* background-color: #e75480; */
  background:rgba(0, 0, 0, .6)
  
}

.x-wallet-dashboard .wallet-card--cashback {
  background-color: white;
  color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.x-wallet-dashboard .wallet-card__title {
  font-size: 24px;
  margin-bottom: 10px;
}

.x-wallet-dashboard .wallet-card__balance {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.x-wallet-dashboard .wallet-card__actions {
  display: flex;
  gap: 10px;
}

.x-wallet-dashboard .button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.x-wallet-dashboard .button--blue {
  background-color: darkgrey;
}

.x-wallet-dashboard .button--blue:hover {
  background-color: grey;
}

.x-wallet-dashboard .button--purple {
  background-color: rgba(190, 182, 182, 0.5);
}

.x-wallet-dashboard .button--purple:hover {
  background-color: rgba(190, 182, 182, 0.8);
}

.x-wallet-dashboard .payment-methods__title {
  font-size: 20px;
  margin-bottom: 15px;
}