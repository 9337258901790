.delivery-address-container {
  border-top: 1px solid rgb(231, 231, 231);
  padding: 15px;
  margin: 15px;
}
.x-pay-details{
  display: flex;
  justify-content: space-between;
}
.delivery-address-description {
  display: flex;
  flex-direction: column;
  color: grey;
}
.delivery-address-description .name {
  margin-bottom: 15px;
}

.delivery-address-description .address {
  margin-bottom: 10px;
}

.delivery-address-container p {
  color: grey;
  margin-bottom: 15px;
  font-weight: bolder;
}

.place-order-btn {
  border: none;
  color: white;
  background-color: #ff9b00;
  padding: 15px 10px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.1rem;
  margin: 10px 0; 
}
.place-order-btn.paystack{
  border: #424242;
  background-color:  #fff;
  color: #424242;
  display: flex;
  align-items: center ;
  justify-content: center;
}
.place-order-btn.pod{
  background-color: var(--primary-color);
}

.place-order-btn.pod:hover {
  cursor: pointer;
  background-color: black;
}
