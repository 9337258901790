
.swap-container
.name-container {
  display: flex;
  flex-flow: column nowrap;
  
}

.swap-container .name-container > label {
  margin-bottom: 5px;
  font-size: .9em;
  color: rgb(108, 108, 108);;
}

.swap-container .name-container input, .name-container textarea,  .name-container select,  .name-container button {
  padding: 12px 10px;
  border-radius: 6px;
  border: 1px solid rgb(210, 210, 210);
}

.swap-success 
.content {
  font-family: 'Helvetica Neue';
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.swap-success  .title { 
  font-weight: bold;
  color: #e7e7e7;
}

.swap-success  .symbol {
  -webkit-text-stroke: 13px #212121;
  font-size: 150px;
  color: #ff2c3c;
}

.swap-success  .text { 
  margin-top: 15px;
  font-size: 25px;
  color: #e7e7e7;
  text-align: center; 
  font-family: 'avenir';
  font-weight: lighter;
}