.address-section-container {
  padding: 20px;
}

.add-address-btn-container button {
  padding: 10px 30px;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  margin-bottom: 15px;
}

.add-address-btn-container button:hover {
  cursor: pointer;
  background-color: black;
}
.add-address-btn-container .plus {
  margin-right: 5px;
}

.address-card .name {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: larger;
}
.profile-address-container {
  display: flex;
  flex-flow: column nowrap;
}
.address-card {
  display: flex;
  flex-direction: column;
}

.address-card .address {
  margin-bottom: 5px;
}
.add-address-btn-container button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address-card span {
  font-weight: 600;
}

.address-card .phone {
  margin-bottom: 10px;
}

.address-btn-container > button {
  margin-right: 15px;
  padding: 6px 18px;
  border: none;
  border-radius: 5px;
}

.address-btn-container > button:hover{
  cursor: pointer;
  background-color: var(--secondary-background-color-onhover);
}

.address-section-container .address-card:not(:first-child) {
  margin-top: 20px;
}
