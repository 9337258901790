.hero-video-container {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  height: 650px;
  background-color: black;
}

.hero-video {
  height: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0.6;
}

.hero-video-container video,
.hero-video-container img {
  object-fit: cover;
  object-position: 0;
}

.hero-text {
  position: absolute;
  color: #fff;
  top: 10em;
  text-align: center;
  align-self: center;
  padding: 0 1em;
}

.hero-text h1 {
  font-size: 2.5em;
  align-self: center;

}

.hero-text h1 svg {
  margin-bottom: -6px;
  color: #FF4B2B;
}

.hero-text h2 {
  margin-top: 30px;
  font-size: 1.2em;
  opacity: 0.8;
  font-weight: 300;
  display: block;

}

.shop-now-btn,
.shop-now-btn-2 {
  padding: 1.1rem 3.5rem;
  font-weight: bolder;
  font-size: 2rem;
  background: transparent;
  border: none;
  position: absolute;
  bottom: 10rem;
  align-self: center;
  justify-self: stretch;
  z-index: 1;
  color: white;
}

.shop-now-btn.btn-2 {
  position: relative;
  bottom: unset;

}

.shop-now-btn::after,
.shop-now-btn::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.shop-now-btn::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background: #FF416C;
  background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
  background: linear-gradient(to right, #FF4B2B, #FF416C);
}

.shop-now-btn::after {
  transform: translate(0px, 0px);
  width: 10px;
  height: 10px;
  background: #a8ff78;
  background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);
  background: linear-gradient(to right, #78ffd6, #a8ff78);
  backdrop-filter: blur(5px);
  border-radius: 50px;
}

.shop-now-btn:hover {
  color: black;
}

.shop-now-btn:hover::before {
  transform: translate(5%, 20%);
  width: 10px;
  height: 10px;
  background: #FF416C;
  background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
  background: linear-gradient(to right, #FF4B2B, #FF416C);
}

.shop-now-btn:hover::after {
  border-radius: 50px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
  background: #a8ff78;
  background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);
  background: linear-gradient(to right, #78ffd6, #a8ff78);
}

.shop-now-btn:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

.shop-now-btn.download{
  display: flex;
  align-items: center;
  font-size: 1.2em;
  padding: 1.1em 2.5em;
  position: relative;
  bottom: 0;
  margin: .2em .5em;
  text-decoration: unset;
}
.shop-now-btn.download span{
  margin-left: 10px;
}
.download-container {
  /* position: absolute; */
  /* top: 20em; */
  /* left: 0; */
  /* right: 0; */
  display: flex;
  justify-content: center;
  margin-top: 2em;
}
.hero-form {
  background: #fff;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  width: 80%;
  margin: auto;
  margin-top: 2em;
}

.hero-search button {
  /* padding: 0rem 3.5rem; */
  font-weight: bolder;
  font-size: 1.5rem;
  background: #FF4B2B;
  border: none;
  flex: 1;
  color: #fff;
}

.hero-search input {
  height: 50px;
  width: 70%;
  padding: 2em;
  border: unset;
  font-size: 1em;
  font-weight: 300;
}

@media (min-width: 768px) {
  .hero-video-container {
    height: 810px;
  }

  .hero-text h1 {
    font-size: 6em;
    align-self: center;
  }

  .hero-text h2 {
    margin-top: 40px;
    font-size: 1.5em;
    opacity: 0.8;
    display: block;
  }
}

@media (max-width: 767px) {
.hero-video-container img {
  object-fit: cover;
  object-position:  left;
}
  .shop-now-btn.download{
  padding: .8em 1.5em;
  font-size: .9em;

  }
.download-container {
  display: flex;
  flex-direction: column ;
   justify-content: center;
  align-items: center;

}
  .hero-form {
    width: 90%;
  }

  .hero-search button {
    font-size: 1rem;
  }

  .hero-search input {
    font-size: .9em;
    padding: 0em 1.5em
  }

  .shop-now-btn.btn-2 {
    font-size: 1.2em;
    padding: .8rem 2.5rem;
  }
}