.promo-modal-container {
  position: fixed;
  inset: 0;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto;
}
.promo-modal-container .promo-input-container {
  /* position: absolute; */
  /* inset: 0; */
 width: 100%;
  max-height: 100%; /* Maximum height to allow scrolling */
  overflow-y: auto; 
}
.promo-modal-container .promo-content{
  background-color: rgb(255, 255, 255);
  margin: auto;
  width: 40%;
  /* height: 520px; */
  /* text-align: center; */
  /* border-radius: 10px; */
  /* padding: 2em 2em 3em; */
  position: relative;
  margin-top: 2em;
  /* display: flex; */
}
 
.promo-modal-container .cross-tab-icon {
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
}
.promo-img{
  width: 50%;
  height: 50vh;
}
.promo-img img{
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.promo-details{
 padding: 2em;
}
.promo-input-container h2 {
  color: rgb(33, 33, 33);
  margin:  10px 0;
  font-weight: 500;
  /* text-align: center; */
  font-size: 2rem;
  font-weight: 600;
}

.promo-details .explore-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .5em;
  /* border-radius: 10px; */
}

.promo-details .explore-btn span{
  /* margin-left: 1em; */
}
 
.promo-details .download-btns{
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px) {

  .promo-modal-container .promo-content{
    width: 90%;
  }
}
