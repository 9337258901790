.checkout-container {
  display: flex;
  flex-flow: column nowrap;
}

.page-heading {
  text-align: center;
  margin: 20px;
  font-weight: 400;
}
.x-payment{
  width: 100%;
  margin: auto;
  padding-top: 3em;
}

.x-payment .payment-content{
  padding-top: 3em;
  margin: 16px;
}
@media (min-width: 768px) {
  .checkout-container {
    flex-flow: row nowrap;
  }
  .x-payment .payment-content{
    width: 50%;
    margin: auto;
  }
}
