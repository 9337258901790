.wishlist-products-container {
  padding: 1em;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.wishlist-page-heading {
  font-weight: 400;
}
.wishlist-products-container .img-container {
  height: 200px;
  bottom: 0em;
  width: 120%;
  object-fit: cover;
  margin-left: -1.5em;
  margin-bottom: -5px;
}

.wishlist-card {
  padding: 1em 1em;
  box-shadow: 0px 0px 46px -4px rgb(238, 238, 238);
  margin: 1em 1em 2em 1em;
  border-radius: 1em;
  width: 280px;
  /* height: 480px; */
  justify-content: space-between;
  display: flex;
  flex-flow: column nowrap;
}
.wishlist-card a{
  text-decoration: none;
}
.wishlist-btn-container {
  position: relative;
  flex-grow: 1;
  /* justify-content: flex-end; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.cart-wishlist-btn {
  font-weight: 800;
  border: none;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 1.05em;
  color: white;
  background-color: var(--primary-color);
  margin-bottom: 9px;
}
.cart-wishlist-btn:hover {
  background-color: black;
  cursor: pointer;
}

.remove-from-wishlist-btn {
  font-weight: 800;
  border: none;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 1.05em;
  color: rgb(0, 0, 0);
  background-color: var(--secondary-background-color);
}
.remove-from-wishlist-btn:hover {
  color: black;
  background-color: var(--secondary-background-color-onhover);
  cursor: pointer;
}

.wishlist-empty-container {
  text-align: center;
  position: absolute;
    top: 40%;
    width: 100%;
}

.wishlist-empty-container h2{
   font-weight: 400;
}

.wishlist-empty-container button {
  text-align: center;
  font-weight: 800;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  color: white;
  background-color: var(--primary-color);
  padding: 0.5rem 2rem;
}

.wishlist-empty-container button:hover {
  background-color: black;
}
