

.why-heading {
  font-size: 2.5em;
  text-align: center;
  margin-top: 50px; 
  margin-left: 20px;
  margin-right: 20px;
  background: linear-gradient(to right, #000000 0%, #5e5e5e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.why-container {

  width: 80%;
  margin: 1em auto 2.5em;
  text-align: center;
}
.why-subtitle {
  margin: 1em 0 1em 0em;
  line-height: 1.5;
  font-size: 1em;
  color: grey;
  font-weight: 300; 
} 

.x-partners{
  margin: 2em 0;
  display: flex;
  justify-content: center;
}
.why-container .x-img{
  width: 160px;
}
/* Tablet (768px - 1023px) */
@media (min-width: 768px) {
  .why-container .x-img{
    width: 200px;
    display: flex;
   align-self: center;
  }

  .why-heading {
    font-size: 5rem;
    margin-top: 80px; 
  }
.why-container{

  width: 60%;
  margin: 2.5em auto;
}
  .why-subtitle {
    font-size: 1.3em;
  }
}
 