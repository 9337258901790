.policy-container{
    font-weight: 300;
    width: 80%;
    margin: auto;
    padding-bottom: 2em;
}
.policy-heading{
    padding: 2em 0 2em;
}
.policy-container h2{
    font-weight: 400;
    margin: 1rem 0 .5rem;
}
.policy-container h3{
    font-weight: 400;
    margin: .5rem 0 0rem;
    color: rgb(108, 108, 108);
}
.policy-desc{
    line-height: 25px;
    color: rgb(108, 108, 108);
}
.policy-desc a{
    text-decoration: none;
}
.policy-desc ul{
    margin-left: 2em;
}
@media (min-width: 768px) {
    .policy-container{
        width: 60%;
        margin: auto;
    }
  
}