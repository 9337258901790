/* ProductImage.css */
.x-reviews{
  border: 1px solid #eee;
  padding: 1.5em 1em;
  margin: 2em 0;
  line-height: 26px;
}
.x-reviews h3{
  font-weight: 500;
}
.x-reviews .x-review-post{
  padding: 1em 0em;
  display: flex;
  background: #f7f7f7;
  margin: 1em 0 0;
  align-items: center;
}
.x-reviews img{
  width: 80px;
  cursor: pointer;
}
.x-reviews .x-content{
  padding: 1em;
}
.x-reviews .x-description{
  font-size: .9em;
}
.x-reviews .x-title{
  font-weight: 500;
}
.x-reviews .x-rating{
  font-weight: 500;
}
.x-reviews .x-ratings svg, .x-reviews .x-rate svg{
  margin-right: .2em;
}
.x-reviews .x-rate svg :hover{
   color: orange;
   cursor: pointer;
}
.x-reviews input {
  padding: 12px 10px;
  border-radius: 6px;
  border: 1px solid rgb(210, 210, 210);
  width: 100%;
  margin: 1em 0;
}
.x-reviews textarea {
  padding: 12px 10px;
  border-radius: 6px;
  border: 1px solid rgb(210, 210, 210);
  width: 100%;
}
.x-box{
  margin-top: 2em;
}
.x-box input[type='submit']{
  border: 0;
  cursor: pointer;
  font-weight: 600;
  background: rgb(232, 232, 232);
  font-size: .9em;
  color: var(--primary-color);
}
  @media (min-width: 768px) {
 
}
