 

.footer{
background:#eee;
padding:20px 0px 30px;
font-family: 'Play', sans-serif;
text-align:center;
}

.footer .row{
width:100%;
margin:1% 0%;
padding:0.6% 0%;
color:gray;
font-size:0.8em;
font-weight: 300;
width: 70%;
margin: auto;
}

.footer .x-downloadimg{
  text-align: center;

  display: flex;
  gap: 10px;
  justify-content: center;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 1em;
}
.footer .x-downloadimg img{
  width: 120px;
}
.footer .row a{
text-decoration:none;
color:gray;
transition:0.5s;
}

.footer .row a:hover{
color:#222;
}

.footer .row ul{
width:100%;
}

.footer .row ul li{
display:inline-block;
margin:0px 30px 10px;
}

.footer .row a.icon{
font-size:2em;
margin:0% 1%;
}

.footer .x-country{
  display: none;
}

  .footer .x-row{
  color:gray;
  font-weight: 300;
  font-size: .8em;
  margin-top: 1.5em;
  }
@media (max-width:767px){
.footer{
text-align:left;
padding:2em 2.5em 2em;
}
.footer .row{
display: flex;
justify-content: space-between;
width: 100%;

}
.footer .row ul li{
display:block;
margin:15px 0px;
text-align:left;
}
.footer .row a.icon{
margin:0% 1em 0 0;
font-size: 1.3rem;
}

.footer .row, .footer .x-row{
  color:gray;
  font-weight: 300;
  font-size: .9em;
  margin-top: 0;
  }
.footer .x-country{
  display: none;
}
.footer .x-downloadimg{
  margin-bottom: 1em;
}
}


 