.verification-container {
    padding: 2em;
    width: 350px;
    margin: auto;
    margin-top: 7vh;
    display: flex;
    flex-flow: column nowrap;
    /* height: 530px; */
    border-radius: 6px;
    /* box-shadow: 0px 2px 33px 3px rgb(234, 234, 234); */
    border: 1px solid #eee
  }
  
  .verification-container .verification-box {
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .verification-container .logo {
    margin-bottom: 40px;
  }
  
  .verification-container  h1 {
    font-size: 24px;
    font-weight: 400;
    color: #111827;
    margin: 0 0 16px 0;
  }
  
  .verification-container  .instruction {
    color: #6B7280;
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 32px 0;
    max-width: 400px;
    font-weight: 300;
  }
  
  .verification-container form {
    width: 100%;
  }
  
  .verification-container .input-group {
    margin-bottom: 24px;
    text-align: left;
  }
  
  .verification-container label {
    display: block;
    font-size: 14px;
    color: #374151;
    margin-bottom: 8px;
    font-weight: 300;
  }
  
  .verification-container input {
    width: 100%;
    padding: 12px 10px;
    border-radius: 6px;
    border: 1px solid rgb(210, 210, 210);
  }
  
  .verification-container  input:focus {
    outline: none;

  }
 
  .verification-container .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #424242;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-bottom: 24px;
  }
  
  .verification-container .submit-button:hover {
    background-color: #3154c4;
  }
  
  .verification-container .recovery-link {
    color: #6B7280;
    font-size: 14px;
  }
  
  .verification-container .recovery-link a {
    color: #4169E1;
    text-decoration: none;
    font-weight: 500;
  }
  
  .verification-container .recovery-link a:hover {
    text-decoration: underline;
  }
  
  