.products-page-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-weight: 300;
  box-sizing: border-box;
  /* margin-top: 20px; */
}

 .x-height{
  min-height: 250px;
 }
@media (min-width: 768px) {
  .products-page-container {
    margin-top: 60px;
    padding: 30px 30px 30px 0px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    /* justify-content: space-evenly; */
  }
}
