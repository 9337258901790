.cart-price-container {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-flow: column nowrap;
  margin: 10px 0 0 0;
  background-color: rgb(242, 242, 242);
  font-weight: 300;
}

.cart-price-container a {
  text-decoration: none;
  color: white;
  background-color: var(--primary-color);
  align-self: center;
  padding: 10px 30px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 40px;
}

.cart-price-container a:hover{
background-color: black;
cursor: pointer;
}

.cart-price-container .total {
  font-weight: 400;
  font-size: 1.2rem;
  color: black;
}
.cart-price-container h1 {
  color: black;
  font-weight: 400;
  font-size: 1.2rem;
}

.cart-price-container > * {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  color: grey;
}

.total-discount-container {
  color: rgb(55, 134, 18);
}

@media (min-width: 768px) {
  .cart-price-container {
    width: 50vw;
    flex-flow: column nowrap;
    margin: 0 0 0 15px;
    align-self: flex-start;
    border-radius: 10px;
    padding: 25px;
    display: flex;
    flex-flow: column nowrap;
  }
}
