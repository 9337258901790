.x-product-div{
  min-height: 250px;
}
@media (min-width: 768px) {
  .page-container {
    display: flex;
  }

  .products-container {
    flex-grow: 1;
  }
}
