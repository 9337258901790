
.product-card-container {
  padding: 1em;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.product-card {
  padding: 1em;
  box-shadow: 0px 0px 46px -4px rgb(238, 238, 238);
  margin: 0em 0em 1em 0em;
  border-radius: 1em;
  width: 280px;
  justify-content: space-between;
  display: flex;
  flex-flow: column nowrap;
}

.product-card-container a {
  text-decoration: unset;
}

.info {
  display: flex;
}

.info>* {
  margin-right: 1em;
}


.product-card-image {
  background-color: rgb(246, 246, 246);
  border-radius: 0.8em;
}

.product-card-image img {
 height: 100%;
 width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.product-card-details {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0.5em 0em;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.9em;
  letter-spacing: 0.5px;
  line-height: 1.3;
  flex-grow: 1;
  z-index: -1;
}

.product-card-details>p {
  color: rgb(108, 108, 108);
  font-size: 0.8em;

  display: flex;
  align-items: center;
}

.product-card-details>p>* {
  margin-right: 5px;
}

.product-card-details>h3 {
  text-transform: uppercase;
  margin: 0em 0.5em 0em 0em;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 0.5em 0em;
  align-self: stretch;
  flex-wrap: nowrap;
  font-weight: 400;
  color: rgb(108, 108, 108);
}

.product-card-buttons {
  position: relative;
  display: flex;
  z-index: 5;
}

.cart-btn {
  font-weight: 600;
  margin: 5px auto 0;
  border: none;
  flex-grow: 1;
  justify-self: stretch;
  border-radius: 10px;
  font-size: .9em;
  color: white;
  background-color: var(--primary-color);
}

.cart-btn:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.product-card-details .ratings {
  font-size: 0.7rem;
  margin-top: -5px;
  margin-bottom: 12px;
}
.x-rel{
  position: relative;
}
.wishlist-btn {
  color: var(--primary-color);
  margin-left: 0.5em;
  border: none;
  background-color: rgb(255, 255, 255);
  padding: 0.2em;
  border-radius: 10px;
  position: absolute;
  right: 0px;
  bottom: 0rem;
  z-index: 5;
  /* box-shadow: 0px 0px 46px -4px rgb(205, 205, 205); */
  /* border: 0.1px solid white; */
}

.wishlist-btn:hover {
  color: black;
  cursor: pointer;
}

.original-price {
  color: rgb(105, 105, 105);
  text-decoration: line-through;
  text-decoration-color: red;
}

.discount-price {
  color: black;
  font-weight: 600;
}

.product-card-details .price-container {
  font-size: 1e;
  display: flex;
  margin-bottom: 0.35em;
}

.price-container>* {
  margin-right: 0.5em;
}

.trending , .out-of-stock , .in-stock, .free-delivery{
  margin-top: 0.8em;
  border-radius: 5px;
  align-self: flex-start;
  padding: 0.3em 1em;
  background-color: rgb(203, 249, 203);
  color: rgb(108, 108, 108);
  font-size: 0.8em;
}

.out-of-stock {
  background-color: rgb(249, 213, 203);
  color: rgb(108, 108, 108);
}

.trending {
  background-color: rgb(238, 238, 238);
  color: rgb(108, 108, 108);
}
.free-delivery {
  background-color: lightgoldenrodyellow;
  color: rgb(108, 108, 108);
}
.load-more-container{
  text-align: center;
  padding: 0 1em 2em;
}
@media (max-width: 767px) {
  .product-card-container.product-column{
    display: block;
  }
  .product-card-container.product-column .product-card{
   width: 100%;
   }
  .product-card-container.product-row{
    display: block;
  }
  .product-card-container.product-row a {
    text-decoration: unset;
    display: flex;
    width: 90%;
  }

  .product-card-container.product-row .product-card {
    flex-flow: row nowrap;
    width: 100%;
  }
  .product-card-container.product-grid .product-card {
    width: 45vw;
  }

  .product-card-container.product-row .product-card-container {
    justify-content: space-between;
    flex-flow: column nowrap;
  }

  .product-card-container.product-row .product-card-details {
    width: 70%;
    padding: 0 2em;
    flex: 2
  }
  .product-card-container .product-card-details h3 {
    font-size: 1.1em;
  }
  .product-card-container.product-row .product-card-image {
    flex: 1
  }
  .product-card-container.product-row .product-card-image > div{
   height: 100%;
  }
  .product-card-container.product-row .cart-btn {
    display: none;
  }

  .product-card-container.product-row .wishlist-btn {
    box-shadow: unset;
  }
}

.no-products-found {
  padding: 2em;
 }
 .no-products-found button{
  margin: 0 20px;
 }
@media (max-width: 767px) {
  .no-products-found {
    text-align: center;
   }
   
}
